<template>
    <section class="content">
        <div class="card">
            <div class="card-body" style="align-self: center; display: flex;">
                <span style="align-self: center; font-size: initial;">Periode</span>&nbsp;&nbsp;
                <div class="input-group">
                  <input type="text" class="form-control" id="daterange" autocomplete="off">
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  </div>
                </div>
            </div>
        </div>
        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser" style="width: 100%;">
                <thead>
                    <tr>
                        <th>Kode</th>
                        <th>Tanggal</th>
                        <th>Cabang</th>
                        <th>Transaksi</th>
                        <th>No Transaksi</th>
                        <th>Masuk</th>
                        <th>Keluar</th>
                        <th>Evidence</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="4">Total:</th>
                        <td class="total-masuk">Rp 0</td>
                        <td class="total-keluar">Rp 0</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Barang</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <select v-model="form.barang_id" class="form-control">
                                    <option v-for="item in optBarang" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Harga Beli</label>
                                <input id="email" class="form-control email" v-model="form.harga_beli" type="text=" required="required" name="email" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <datepicker v-model="form.transaksi_tanggal" :options="{format:'dd/mm/yyyy', startView: 'days', minViewMode: 'days'}" :change="onChange()"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tipe Transaksi</label>
                                <select v-model="form.transaksi_tipe" class="form-control" @input="changeLabel($event)">
                                    <option v-for="item in optType" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label qty">Qty</label>
                                <input id="email" class="form-control email" v-model="form.qty" type="text=" required="required" name="email" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Cabang</label>
                                <select v-model="form.branch_id" class="form-control">
                                    <option v-for="item in optCabang" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Keterangan</label>
                                <textarea class="form-control" v-model="form.keterangan"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import Swal from "sweetalert2";
import datepicker from '@/components/Datepicker';
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";

window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

let idr = Intl.NumberFormat("en-ID", {
    style: "currency",
    currency: "IDR",
    useGrouping: true,
    maximumSignificantDigits: 3,
});

export default {
    name: 'DataGuru',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            optCabang: [],
            optBarang: [],
            optType: [
                {id: '0', label: 'Barang Masuk'},
                {id: '1', label: 'Barang Keluar'},
                {id: '2', label: 'Stock Opname'}
            ],
            form: {
                barang_id: '',
                harga_beli: '',
                transaksi_tanggal: '',
                transaksi_tipe: '',
                qty: '',
                branch_id: '',
                keterangan: '',
            },
            date_start: moment().format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD')
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/management/inventaris/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optCabang = js.data;
            })

        authFetch('/management/inventaris/barang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBarang = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
                this.$router.push('/inventaris-detail/'+e.srcElement.getAttribute('data-id'));
            }
            if (e.srcElement.getAttribute('class') == 'download btn btn-primary') {
                this.loadingDownload = true;
                Swal.fire({
                      title: "Mohon tunggu...",
                      text: "Sedang mengambil data",
                      imageUrl: "images/ajaxloader.gif",
                      showConfirmButton: false,
                      allowOutsideClick: false,
                      buttons: false,
                    });
                this.download(e.srcElement.getAttribute('data-href'), e.srcElement.getAttribute('data-name'));
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-info preview') {
                this.preview(e.srcElement.getAttribute('data-href'));
            }
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal) {
                var split = this.form.tanggal.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var self = this;
            this.errors = [];

            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/management/inventaris';
            if (this.method == 'PUT') urlSubmit = '/management/inventaris/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/data-guru/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/akademik/data_guru/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        },
        changeLabel: function(event) {
            console.log(event.target.value)
            if (event.target.value == '2') {
                $('.qty').html('Qty Aktual');
            } else {
                $('.qty').html('Qty');
            }
        },
        download: function(code, name) {
            authFetch("/accounting/pengajuan/download/"+code, {
                method: 'GET',
                headers: {
                'Content-Type': null,
                }
            })
            .then(response => {
              // console.log(response)
              // const filename =  response.headers.get('Content-Disposition');//.split('filename=')[1];
              response.blob().then(blob => {
                  this.loadingDownload = false;
                  Swal.fire({
                      title: "Sukses",
                      text: "Load data berhasil",
                      showConfirmButton: false,
                      showOkButton: false,
                      buttons: false,
                      timer: 1000
                    });
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement('a');
                  a.href = url;
                  a.download = name;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
              });
            })
        },
        preview: function(data) {
            window.open(window.$apiUrl+data, "_blank");
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        let table = this.table = createTable(e.tableuser, {
            "title": "List",
            "roles": this.$route.params.roles,
            "ajax": "/keuangan/keuangan_pusat",
            scrollX: true,
            "columns": [
                { "data": "nama_transaksi" },
                { "data": "tanggal" },
                { "data": "branch_name" },
                { "data": "deskripsi" },
                { "data": "nomor_transaksi" },
                { "data": "nilai_masuk", sortable:false },
                { "data": "nilai_keluar", sortable:false },
                { "data": "file_transaksi", sortable:false },
            ],
            filterBy: [1, 2],
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
            paramData: function ( d ) {
                d.date_start = self.date_start;
                d.date_end = self.date_end;
            },
            "rowCallback": function(row, data) {
                // $('td:eq(5)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'">Detail</a>');
                if (data.deskripsi.indexOf('Siswa 1') != -1) {
                    $('td:eq(3)', row).html(data.deskripsi.replace('Siswa 1', 'Siswa'));
                }
                if (data.deskripsi.indexOf('Siswa 2') != -1) {
                    $('td:eq(3)', row).html(data.deskripsi.replace('Siswa 2', 'Siswa Pendaftaran'));
                }
                $('td:eq(5)', row).html(idr.format(data.nilai_masuk));
                $('td:eq(6)', row).html(idr.format(data.nilai_keluar));
                if (data.file_transaksi) {
                    $('td:eq(7)', row).html('<div class="row"><a type="button" class="btn btn-info preview" data-href="/file/view/'+data.file_transaksi+'" data-name="'+data.file_actual+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="download btn btn-primary" data-href="'+data.file_transaksi+'" data-name="'+data.file_actual+'"><i class="fas fa-download" style="pointer-events:none"></i></a></div>');
                } else {
                    $("td:eq(7)", row).html('<a type="button" class="btn btn-primary" data-toggle="modal"style="color:white" data-id="'+data.id_transaksi+'">Upload</a>');
                }
            },
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;
     
                var masuk = 0;
                var keluar = 0;
                data.map(function(val,key){
                    masuk = masuk + parseFloat(val.nilai_masuk);
                    keluar = keluar + parseFloat(val.nilai_keluar);
                });

                $('.total-masuk').html(idr.format(masuk));
                $('.total-keluar').html(idr.format(keluar));
            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Pusat '+self.date_start+' sd '+self.date_end;
                    },
                },
            ],
        });
        new Daterangepicker(this.$refs.daterange, {
            }, function(d1, d2) {
            });
        $('#daterange').daterangepicker({ startDate: moment(), endDate: moment() });
        $('#daterange').on('apply.daterangepicker', function(ev, picker) {
            self.date_start = picker.startDate.format('YYYY-MM-DD')
            self.date_end = picker.endDate.format('YYYY-MM-DD')
            table.api().ajax.reload();
        });
    }
}
</script>